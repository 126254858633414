import { PROCESSOR_STATUS } from '@commons/data/constants';
import {
  beatufyApiErrorMessage as beautifyApiErrorMessage,
  improvedStructuredClone
} from '@commons/helpers/utils';
import axios from 'axios';
import FileDownload from 'js-file-download';
import qs from 'qs';
import { formatDateSelectors } from '../helpers/date';

// Mutation types
const GET_SUBMERCHANTS = 'SUBMERCHANTS/GET_SUBMERCHANTS';
const GET_ACCOUNTS_TABLE = 'SUBMERCHANTS/GET_ACCOUNTS_TABLE';
const SET_ACCOUNTS_TABLE_DATA = 'SUBMERCHANTS/SET_ACCOUNTS_TABLE_DATA';
const SET_SUBMERCHANT_APPLICATION = 'SUBMERCHANTS/SET_SUBMERCHANT_APPLICATION';
const UPDATE_APPLICATION_RULES = 'SUBMERCHANTS/UPDATE_APPLICATION_RULES';
const CHANGE_ACCOUNT_STATUS = 'SUBMERCHANTS/CHANGE_ACCOUNT_STATUS';
const ADD_NOTE = 'SUBMERCHANTS/ADD_NOTE';
const SET_MY_SUBMERCHANTS = 'SUBMERCHANTS/SET_MY_SUBMERCHANTS';
const SET_ACCOUNT_NAMES = 'SUBMERCHANTS/SET_ACCOUNT_NAMES';
const SET_SUBMERCHANT = 'SUBMERCHANTS/SET_SUBMERCHANT';
const SET_FULL_ACCOUNT_DATA = 'SUBMERCHANTS/SET_FULL_ACCOUNT_DATA';
const SET_PREFERRED_ACCOUNT = 'SUBMERCHANTS/SET_PREFERRED_ACCOUNT';
const ADD_ACCOUNT = 'SUBMERCHANTS/ADD_ACCOUNT';
const SET_SUBMERCHANT_LOANS = 'SUBMERCHANTS/SET_SUBMERCHANT_LOANS';
const SET_ACCOUNTS_CHECKS = 'SUBMERCHANTS/SET_ACCOUNTS_CHECKS';
const SET_LOAN_ELIGIBLE = 'SUBMERCHANTS/SET_LOAN_ELIGIBLE';
const SET_UNDERWRITING_STATUS = 'SUBMERCHANTS/SET_UNDERWRITING_STATUS';
const SET_SUBMERCHANT_CHECKS = 'SUBMERCHANTS/SET_SUBMERCHANT_CHECKS';
const SET_SUBMERCHANT_SYNCS = 'SUBMERCHANTS/SET_SUBMERCHANT_SYNCS';
const UPDATE_SUBMERCHANT_CHECK = 'SUBMERCHANTS/UPDATE_SUBMERCHANT_CHECKS';
const UPDATE_SUBMERCHANT_RULES = 'SUBMERCHANTS/UPDATE_SUBMERCHANT_RULES';
const UPDATE_SUBMERCHANT_RULE = 'SUBMERCHANTS/UPDATE_SUBMERCHANT_RULE';
const UPDATE_APPLICANT_RULE = 'SUBMERCHANTS/UPDATE_APPLICANT_RULE';
const SET_ACKNOWLEDGE = 'SUBMERCHANTS/SET_ACKNOWLEDGE';
const SET_SUBMERCHANT_DOCS = 'SUBMERCHANTS/SET_SUBMERCHANT_DOCS';
const SET_SUBMERCHANT_RISK_METRICS = 'SUBMERCHANTS/UPDATE_SUBMERCHANT_RISK_METRICS';

const BASE_ACCOUNTS_ENDPOINT = 'backoffice/accounts';
const BASE_SUBMERCHANT_ENDPOINT =
  'backoffice/accounts/{id}/submerchants/{subMerchantObjId}';
const BASE_PROCESSOR_ENDPOINT = `${BASE_SUBMERCHANT_ENDPOINT}/processors/{processorType}`;
const ENDPOINTS = {
  ACCOUNTS: BASE_ACCOUNTS_ENDPOINT,
  CLONE: `${BASE_ACCOUNTS_ENDPOINT}/clone`,
  SUBMERCHANTS: `${BASE_ACCOUNTS_ENDPOINT}/submerchants`,
  SINGLE_ACCOUNT: `${BASE_ACCOUNTS_ENDPOINT}/{id}`,
  SINGLE_ACCOUNT_OPEN: `${BASE_ACCOUNTS_ENDPOINT}/{id}/open`,
  TERMS_RULE: `${BASE_ACCOUNTS_ENDPOINT}/{id}/terms`,
  RECHECK_ACCOUNT: `${BASE_ACCOUNTS_ENDPOINT}/{id}/checks/{type}`,
  ACCOUNT_LOANS: `${BASE_ACCOUNTS_ENDPOINT}/{id}/loans`,
  ACCOUNT_LOAN: `${BASE_ACCOUNTS_ENDPOINT}/{id}/loans/{subMerchantLoanId}`,
  ACCOUNT_LOAN_SUBMIT: `${BASE_ACCOUNTS_ENDPOINT}/{id}/loans/{subMerchantLoanId}/submit`,
  ACCOUNT_LOAN_ELIGIBLE: `${BASE_ACCOUNTS_ENDPOINT}/loans/eligible`,
  SUBMERCHANT: BASE_SUBMERCHANT_ENDPOINT,
  ACTIVATE_SUBMERCHANT: `${BASE_SUBMERCHANT_ENDPOINT}/activate`,
  ECHECK: `${BASE_PROCESSOR_ENDPOINT}/echeck`,
  SINGLE_ACCOUNT_PROCESSOR: BASE_PROCESSOR_ENDPOINT,
  UNDERWRITING_STATUS: `${BASE_PROCESSOR_ENDPOINT}/underwriting-status`,
  PROVISION_PROCESSOR: `${BASE_PROCESSOR_ENDPOINT}/provision`,
  SET_DEFAULT_PROCESSOR: `${BASE_PROCESSOR_ENDPOINT}/default`,
  SUBMERCHANT_CHECKS: `${BASE_ACCOUNTS_ENDPOINT}/checks/insights`,
  SUBMERCHANT_SYNCS: `${BASE_ACCOUNTS_ENDPOINT}/syncs`,
  CHECKS_ACCOUNT: `${BASE_ACCOUNTS_ENDPOINT}/checks`,
  MCC_ENDPOINT: `${BASE_ACCOUNTS_ENDPOINT}/mcc`,
  FULL_ACCOUNT_DATA: `${BASE_ACCOUNTS_ENDPOINT}/submerchants/payoutbankaccount/getinfo`,
  SUBMIT_SM_APP: `${BASE_ACCOUNTS_ENDPOINT}/submit`,
  APPROVE_SM_APP: `${BASE_ACCOUNTS_ENDPOINT}/{id}/approve`,
  CHECKS_SUBMERCHANT: `${BASE_SUBMERCHANT_ENDPOINT}/checks`,
  UPDATE_MERCHANT_RULE: `${BASE_ACCOUNTS_ENDPOINT}/submerchant/rules/update-manually`,
  UPDATE_APPLICANT_RULE: `${BASE_ACCOUNTS_ENDPOINT}/applicant/rules/update-manually`,
  ACCOUNT_CHECKS: `${BASE_ACCOUNTS_ENDPOINT}/{id}/accounts-checks`,
  GET_RESERVED_FUNDS: `${BASE_ACCOUNTS_ENDPOINT}/{id}/reserved`,
  RELEASE_FUNDS: `${BASE_ACCOUNTS_ENDPOINT}/{id}/release-funds`,
  UPDATE_SUBMERCHANT_OWNERSHIP_TYPE: `${BASE_SUBMERCHANT_ENDPOINT}/ownership-type`,
  UPDATE_SUBMERCHANT_RISK_METRICS: `${BASE_SUBMERCHANT_ENDPOINT}/risk-metrics`
};

function initialState() {
  return {
    list: {},
    accountList: {},
    subMerchantApplication: { logs: [] },
    mySubMerchants: {},
    accountNames: JSON.parse(localStorage.getItem('accountNames')) || [],
    subMerchant: {},
    subMerchantLoans: {},
    accountChecks: [],
    loanEligible: {},
    underwritingStatus: {},
    subMerchantChecks: [],
    applicantChecks: [],
    fullAccountData: null
  };
}

// initial state
const state = initialState();

// actions
const actions = {
  GET_SUBMERCHANTS({ commit }, data) {
    const params = formatDateSelectors(data);
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SUBMERCHANTS, {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { indices: false, allowDots: true })
        })
        .then(response => {
          commit(GET_SUBMERCHANTS, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error loading submerchants'));
    });
  },
  async GET_ACCOUNTS_TABLE({ commit }, data) {
    const params = formatDateSelectors(data);
    if (params.owners) {
      params['identity.owners.firstName'] = params.owners;
      params['identity.owners.lastName'] = params.owners;
      delete params.owners;
    }
    if (params.business) {
      params['subMerchantDocs.identity.business.dbaName'] = params.business;
      params['subMerchantDocs.identity.business.legalName'] = params.business;
      delete params.business;
    }
    try {
      const response = await axios.get(ENDPOINTS.ACCOUNTS, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: false }),
        cancelPreviousRequests: true
      });
      commit(GET_ACCOUNTS_TABLE, response.data);
      return response.data;
    } catch (error) {
      throw new Error('Error loading applications');
    }
  },
  async DOWNLOAD_ACCOUNTS_TABLE(context, data) {
    const params = formatDateSelectors(data);
    if (params.owners) {
      params['identity.owners.firstName'] = params.owners;
      params['identity.owners.lastName'] = params.owners;
      delete params.owners;
    }
    if (params.mainOwner) {
      params.identity = {
        owners: { firstName: params.mainOwner, lastName: params.mainOwner }
      };
      delete params.mainOwner;
    }
    if (params.business) {
      params['subMerchantDocs.identity.business.dbaName'] = params.business;
      params['subMerchantDocs.identity.business.legalName'] = params.business;
      delete params.business;
    }
    const response = await axios.get(ENDPOINTS.ACCOUNTS, {
      params: { ...params, exportFormat: 'csv' },
      paramsSerializer: params =>
        qs.stringify(params, { indices: false, allowDots: false }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/csv'
      },
      cancelPreviousRequests: true
    });
    FileDownload(response.data, 'accounts.csv');
  },
  CLEAR_ACCOUNTS({ commit }) {
    commit(GET_SUBMERCHANTS, initialState().list);
    commit(GET_ACCOUNTS_TABLE, initialState().list);
  },
  CLEAR_MY_SUBMERCHANTS({ commit }) {
    commit(SET_MY_SUBMERCHANTS, initialState().mySubMerchants);
  },
  GET_SUBMERCHANT_APPLICATION({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SINGLE_ACCOUNT.replace('{id}', id))
        .then(response => {
          commit(SET_SUBMERCHANT_APPLICATION, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error in GET_SUBMERCHANT_APPLICATION'));
    });
  },
  DELETE_PROCESSOR_ACCOUNT(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          ENDPOINTS.SINGLE_ACCOUNT_PROCESSOR.replace('{id}', data.id)
            .replace('{subMerchantObjId}', data.subMerchantObjId)
            .replace('{processorType}', data.processorType),
          data
        )
        .then(response => {
          return resolve(response.data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            return reject(e.response.data.message);
          }
          reject(e.message);
        });
    });
  },
  PROVISION_PROCESSOR({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ENDPOINTS.PROVISION_PROCESSOR.replace('{id}', data.id)
            .replace('{subMerchantObjId}', data.subMerchantObjId)
            .replace('{processorType}', data.processorType)
        )
        .then(response => {
          if (response.data && response.data.success) {
            const smApp = structuredClone(state.subMerchantApplication);
            const smDocIdx = smApp.subMerchantDocs.findIndex(
              s => s._id === data.subMerchantObjId
            );
            smApp.subMerchantDocs[smDocIdx].processor[data.processorType] = {
              status: PROCESSOR_STATUS.PROVISIONED
            };
            commit(SET_SUBMERCHANT_APPLICATION, smApp);
          }
          resolve(response.data);
        })
        .catch(errorResponse => {
          const { response } = errorResponse;
          let message = null;
          if (response.data.data)
            message = beautifyApiErrorMessage(response.data.data.message);
          reject(message || null);
        });
    });
  },
  SET_DEFAULT_PROCESSOR(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ENDPOINTS.SET_DEFAULT_PROCESSOR.replace('{id}', data.id)
            .replace('{subMerchantObjId}', data.subMerchantObjId)
            .replace('{processorType}', data.processorType)
        )
        .then(response => resolve(response.data))
        .catch(errorResponse => {
          const { response } = errorResponse;
          let message = null;
          if (response.data.data)
            message = beautifyApiErrorMessage(response.data.data.message);
          reject(message || null);
        });
    });
  },
  CLEAR_SUBMERCHANT_APPLICATION({ commit }) {
    commit(SET_SUBMERCHANT_APPLICATION, { logs: [] });
  },
  UPDATE_ACCOUNT(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.SINGLE_ACCOUNT.replace('{id}', data.id), data)
        .then(() => resolve(true))
        .catch(errorResponse => {
          const { response } = errorResponse;
          let message = null;
          if (response && response.data)
            message = beautifyApiErrorMessage(response.data.message);
          reject(message || null);
        });
    });
  },
  CHANGE_ACCOUNT_STATUS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.SINGLE_ACCOUNT.replace('{id}', data.id), data)
        .then(response => {
          commit(CHANGE_ACCOUNT_STATUS, data.status);
          resolve(response.data);
        })
        .catch(response => {
          reject(response.response.data.message);
        });
    });
  },
  async RECHECK_ACCOUNT({ commit }, data) {
    try {
      const url = ENDPOINTS.RECHECK_ACCOUNT.replace('{id}', data.id).replace(
        '{type}',
        data.type
      );
      const response = await axios.get(url);
      commit(UPDATE_APPLICATION_RULES, response.data);
      return true;
    } catch (error) {
      throw error.response?.data?.message;
    }
  },
  ADD_NOTE({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.SINGLE_ACCOUNT.replace('{id}', data.id), data)
        .then(response => {
          commit(ADD_NOTE, response.data);
          resolve(true);
        })
        .catch(response => {
          reject(response.response.data.message);
        });
    });
  },
  GET_UNDERWRITING_STATUS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          ENDPOINTS.UNDERWRITING_STATUS.replace('{id}', data.id)
            .replace('{subMerchantObjId}', data.subMerchantObjId)
            .replace('{processorType}', data.processorType)
        )
        .then(response => {
          const data = response.data.success ? response.data : {};
          commit(SET_UNDERWRITING_STATUS, data);
          resolve(data);
        })
        .catch(() => reject());
    });
  },
  async GET_SUBMERCHANT_SYNCS({ commit }, data) {
    let params = data;
    try {
      const response = await axios.post(ENDPOINTS.SUBMERCHANT_SYNCS, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: true })
      });
      let results = response.data.success ? response.data.data : [];
      commit(SET_SUBMERCHANT_SYNCS, results);
    } catch (error) {
      throw error.response?.data?.message;
    }
  },
  async CLEAR_SYNCS({ commit }) {
    try {
      commit(SET_SUBMERCHANT_SYNCS, []);
    } catch (error) {
      throw error;
    }
  },
  async GET_SUBMERCHANT_CHECKS({ commit }, data) {
    let params = data;
    try {
      const response = await axios.post(ENDPOINTS.SUBMERCHANT_CHECKS, {
        params,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: true })
      });
      const result = response.data.success ? response.data.data : [];
      commit(SET_SUBMERCHANT_CHECKS, result);
    } catch (error) {
      throw response.response.data.message;
    }
  },
  async REFRESH_SUBMERCHANT_CHECKS({ commit }, data) {
    try {
      const { checkId } = data;
      const response = await axios.post(`${ENDPOINTS.SUBMERCHANT_CHECKS}/refresh`, {
        checkId
      });
      const check = response.data.data;
      commit(UPDATE_SUBMERCHANT_CHECK, check);
    } catch (error) {
      console.log(error);
      throw new Error('Error refreshing the subMerchant checks.');
    }
  },
  CLEAR_SUBMERCHANT_CHECKS({ commit }) {
    commit(SET_SUBMERCHANT_CHECKS, []);
  },
  UPDATE_ACCOUNT_OPEN(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.SINGLE_ACCOUNT_OPEN.replace('{id}', data.id))
        .then(() => resolve(true))
        .catch(response => {
          reject(response.response.data.message);
        });
    });
  },
  UPDATE_ECHECK({ commit, state }, { id, subMerchantObjId, processorType, enabled }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          ENDPOINTS.ECHECK.replace('{id}', id)
            .replace('{subMerchantObjId}', subMerchantObjId)
            .replace('{processorType}', processorType),
          { enabled }
        )
        .then(response => {
          const { data } = response;
          if (data.success) {
            let smApplication = improvedStructuredClone(state.subMerchantApplication);
            const subMerchant = smApplication.subMerchantDocs.find(
              s => s._id === subMerchantObjId
            );
            subMerchant.processor[processorType].echeck = data.data;
            commit(SET_SUBMERCHANT_APPLICATION, smApplication);
          }
          resolve(response.data.success ? response.data.data : {});
        })
        .catch(() => reject());
    });
  },
  GET_ACCOUNT_NAMES({ commit }) {
    const params = {
      fields:
        'identity.business.dbaName,identity.business.billingDescriptor,identity.business.location,subMerchantId,tokenization.publicKey,processor,payout.mode,settings,features'
    };
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.SUBMERCHANTS, { params })
        .then(response => {
          commit(SET_ACCOUNT_NAMES, response.data.data);
          localStorage.setItem('accountNames', JSON.stringify(response.data.data));
          resolve();
        })
        .catch(() => reject());
    });
  },
  RUN_CHECKS(context, { smApplicationId, subMerchantObjId, browserInfo }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.CHECKS_ACCOUNT, {
          smApplicationId,
          subMerchantObjId,
          browserInfo
        })
        .then(() => resolve(true))
        .catch(response => {
          reject(response.response.data.message);
        });
    });
  },
  CLONE_ACCOUNT(context, { subMerchantObjId, smApplicationId, spProductId }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.CLONE, {
          subMerchantObjId,
          smApplicationId,
          spProductId
        })
        .then(response => resolve(response.data))
        .catch(error => reject(error.message));
    });
  },
  async UPDATE_SUBMERCHANT(context, data) {
    try {
      // used by underwriter/admin
      const response = await axios.put(
        ENDPOINTS.SUBMERCHANT.replace('{id}', data.id).replace(
          '{subMerchantObjId}',
          data.subMerchantObjId
        ),
        data
      );
      return response.data;
    } catch (error) {
      const { response } = error;
      let message = null;
      if (response.data) message = beautifyApiErrorMessage(response.data.message);
      throw message || error;
    }
  },
  ACTIVATE_SUBMERCHANT(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ENDPOINTS.ACTIVATE_SUBMERCHANT.replace('{id}', data.id).replace(
            '{subMerchantObjId}',
            data.subMerchantObjId
          ),
          data
        )
        .then(response => resolve(response.data))
        .catch(errorResponse => {
          const { response } = errorResponse;
          let message = null;
          if (response.data.data)
            message = beautifyApiErrorMessage(response.data.data.message);
          reject(message || null);
        });
    });
  },
  GET_SUBMERCHANT({ commit }, { smApplicationId, subMerchantObjId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          ENDPOINTS.SUBMERCHANT.replace('{id}', smApplicationId).replace(
            '{subMerchantObjId}',
            subMerchantObjId
          )
        )
        .then(response => {
          commit(SET_SUBMERCHANT, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error in GET_SUBMERCHANT'));
    });
  },
  GET_FULL_ACCOUNT_DATA({ commit }, { bankAccountToken }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.FULL_ACCOUNT_DATA, { bankAccountToken })
        .then(response => {
          commit(SET_FULL_ACCOUNT_DATA, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error in GET_FULL_ACCOUNT_DATA'));
    });
  },
  CLEAR_SUBMERCHANT({ commit }) {
    commit(SET_SUBMERCHANT, {});
  },
  CLEAR_ACCOUNT_DATA({ commit }) {
    commit(SET_FULL_ACCOUNT_DATA, null);
  },
  GET_SUBMERCHANTS_LOANS({ commit }, { smApplicationId, params }) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.ACCOUNT_LOANS.replace('{id}', smApplicationId), {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { indices: false, allowDots: true })
        })
        .then(response => {
          commit(SET_SUBMERCHANT_LOANS, response.data);
          resolve(response.data);
        })
        .catch(() => reject('Error loading submerchants loans'));
    });
  },
  async GET_CHECKS_HISTORY({ commit }, { smApplicationId, params }) {
    try {
      params = formatDateSelectors(params);
      const response = await axios.get(
        ENDPOINTS.ACCOUNT_CHECKS.replace('{id}', smApplicationId),
        {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { indices: false, allowDots: true })
        }
      );
      commit(SET_ACCOUNTS_CHECKS, response.data);
    } catch (error) {
      console.log({ error });
      throw new Error('Error loading accounts checks');
    }
  },
  async DELETE_ACCOUNTS_CHECK({ commit }, { smApplicationId, checkObjId }) {
    try {
      return await axios.delete(
        `${ENDPOINTS.ACCOUNT_CHECKS.replace('{id}', smApplicationId)}/${checkObjId}`
      );
    } catch (error) {
      console.log({ error });
      throw new Error('Error removing accounts check');
    }
  },
  CLEAR_SUBMERCHANTS_LOANS({ commit }) {
    commit(SET_SUBMERCHANT_LOANS, {});
  },
  ADD_SUBMERCHANT_LOAN(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.ACCOUNT_LOANS.replace('{id}', data.smApplicationId), data)
        .then(() => resolve())
        .catch(() => reject('Error adding a submerchants loan'));
    });
  },
  EDIT_SUBMERCHANT_LOAN(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          ENDPOINTS.ACCOUNT_LOAN.replace('{id}', data.smApplicationId).replace(
            '{subMerchantLoanId}',
            data._id
          ),
          data
        )
        .then(() => resolve())
        .catch(() => reject('Error editing a submerchants loan'));
    });
  },
  DELETE_SUBMERCHANT_LOAN(context, { smApplicationId, subMerchantLoanId }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          ENDPOINTS.ACCOUNT_LOAN.replace('{id}', smApplicationId).replace(
            '{subMerchantLoanId}',
            subMerchantLoanId
          )
        )
        .then(() => resolve())
        .catch(() => reject('Error removing a submerchants loan'));
    });
  },
  SUBMIT_LOAN_APPLICATION(context, { smApplicationId, subMerchantLoanId }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ENDPOINTS.ACCOUNT_LOAN_SUBMIT.replace('{id}', smApplicationId).replace(
            '{subMerchantLoanId}',
            subMerchantLoanId
          )
        )
        .then(() => resolve())
        .catch(error => {
          const { response } = error;
          if (
            response.data &&
            response.data.message &&
            response.data.message.includes('template')
          )
            reject(response.data.message);
          else reject('Error adding a submerchants loan');
        });
    });
  },
  GET_LOAN_ELIGIBLE({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.ACCOUNT_LOAN_ELIGIBLE)
        .then(response => commit(SET_LOAN_ELIGIBLE, response.data))
        .catch(() => reject());
    });
  },
  GET_MCC_CODES() {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.MCC_ENDPOINT)
        .then(response => resolve(response.data))
        .catch(() => reject('Error while getting the MCC Codes. Try again later.'));
    });
  },
  SUBMIT_SM_APP(context, { smApplicationId, subMerchantObjId, browserInfo }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.SUBMIT_SM_APP, {
          smApplicationId,
          subMerchantObjId,
          browserInfo
        })
        .then(() => resolve(true))
        .catch(response => {
          reject(response.response.data.message);
        });
    });
  },
  APPROVE_SM_APP(context, smApplicationId) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.APPROVE_SM_APP.replace('{id}', smApplicationId))
        .then(response => resolve(response.data))
        .catch(response => reject(response.response.data.data.message));
    });
  },
  async CHECKS_SUBMERCHANT({ commit }, data) {
    try {
      const url = ENDPOINTS.CHECKS_SUBMERCHANT.replace(
        '{id}',
        data.smApplicationId
      ).replace('{subMerchantObjId}', data.subMerchantObjId);
      const response = await axios.get(url, {
        params: {
          type: data.type
        }
      });
      const rulesData = {
        rules: { ...response.data.data },
        subMerchantObjId: data.subMerchantObjId,
        smApplicationId: data.smApplicationId
      };
      commit(UPDATE_SUBMERCHANT_RULES, rulesData);
    } catch (error) {
      throw error.response.data.message ?? error;
    }
  },
  async UPDATE_MERCHANT_RULE_MANUALLY({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.UPDATE_MERCHANT_RULE, data);
      if (!response.data.success) throw 'Rule update failed';
      commit(UPDATE_SUBMERCHANT_RULE, data);
    } catch (error) {
      throw error;
    }
  },
  async UPDATE_APPLICANT_RULE_MANUALLY({ commit }, data) {
    try {
      const response = await axios.post(ENDPOINTS.UPDATE_APPLICANT_RULE, data);
      if (!response.data.success) throw 'Rule update failed';
      commit(UPDATE_APPLICANT_RULE, data);
    } catch (error) {
      throw error;
    }
  },
  async GET_RESERVED_FUNDS({ commit }, data) {
    try {
      const response = await axios.get(
        ENDPOINTS.GET_RESERVED_FUNDS.replace('{id}', data.subMerchantId)
      );
      if (!response.data.success) throw 'Could not fetch reserved funds';
      return response.data.data.amount;
    } catch (error) {
      throw error;
    }
  },
  async RELEASE_FUNDS({ commit }, data) {
    try {
      const response = await axios.post(
        ENDPOINTS.RELEASE_FUNDS.replace('{id}', data.subMerchantId),
        data
      );
      if (!response.data.success) throw 'Could not release funds';
      return response.data.data.data.reserved;
    } catch (error) {
      throw error;
    }
  },
  UPDATE_SUBMERCHANT_OWNERSHIP_TYPE({ commit, state }, ownershipUpdates) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          ENDPOINTS.UPDATE_SUBMERCHANT_OWNERSHIP_TYPE.replace('/{subMerchantObjId}', ''),
          { ownershipUpdates }
        )
        .then(response => {
          if (!response.data.success) throw new Error(response.data.message);
          const { updatedSubMerchants } = response.data;

          const updatedDocs = state.subMerchantApplication.subMerchantDocs.map(doc => {
            const update = updatedSubMerchants.find(update => update.id === doc.id);
            return update
              ? {
                ...doc,
                identity: { ...doc.identity, ownershipType: update.ownershipType }
              }
              : doc;
          });

          commit(SET_SUBMERCHANT_DOCS, updatedDocs);

          resolve(updatedSubMerchants);
        })
        .catch(error => {
          console.error('Failed to update submerchant ownership type:', error);
          reject(error);
        });
    });
  },
  async UPDATE_SUBMERCHANT_RISK_METRICS({ commit }, { subMerchantId, riskMetrics }) {
    try {
      const response = await axios.put(
        ENDPOINTS.UPDATE_SUBMERCHANT_RISK_METRICS.replace('/{subMerchantObjId}', ''),
        {
          subMerchantId,
          riskMetrics
        }
      );
      if (!response.data.success) throw new Error(response.data.message);
      commit(SET_SUBMERCHANT_RISK_METRICS, { subMerchantId, riskMetrics });
      return response.data.success;
    } catch (error) {
      console.error('Failed to update submerchant risk parameters:', error);
      throw new Error(`Error updating submerchant risk parameters: ${error.message}`);
    }
  },
  async UPDATE_CYBESOURCE_ENABLED({ commit, state }, { id, subMerchantObjId, enabled }) {
    try {
      const response = await axios.put(
        ENDPOINTS.SUBMERCHANT.replace('{id}', id).replace(
          '{subMerchantObjId}',
          subMerchantObjId
        ),
        { processor: { cybersource: { enabled } } }
      );
      const { data } = response;
      let smApplication = improvedStructuredClone(state.subMerchantApplication);
      const subMerchant = smApplication.subMerchantDocs.find(
        s => s._id === subMerchantObjId
      );
      subMerchant.processor.cybersource.enabled = enabled;
      commit(SET_SUBMERCHANT_APPLICATION, smApplication);
      return { success: true };
    } catch (error) {
      const { response } = error;
      let message = null;
      if (response.data) message = beautifyApiErrorMessage(response.data.message);
      throw message || error;
    }
  },
  async UPDATE_DECISION_MANAGER_ENABLED({ commit, state }, { id, subMerchantObjId, enabled }) {
    try {
      const response = await axios.put(
        ENDPOINTS.SUBMERCHANT.replace('{id}', id).replace(
          '{subMerchantObjId}',
          subMerchantObjId
        ),
        { processor: { cybersource: { decisionManager: { enabled } } } }
      );
      const { data } = response;
      let smApplication = improvedStructuredClone(state.subMerchantApplication);
      const subMerchant = smApplication.subMerchantDocs.find(
        s => s._id === subMerchantObjId
      );
      subMerchant.processor.cybersource.decisionManager.enabled = enabled;
      commit(SET_SUBMERCHANT_APPLICATION, smApplication);
      return { success: true };
    } catch (error) {
      const { response } = error;
      let message = null;
      if (response.data) message = beautifyApiErrorMessage(response.data.message);
      throw message || error;
    }
  }
};

// getters
const getters = {
  getSubMerchants: state => state.list,
  getTableAccounts: state => state.accountList,
  getSubMerchantApplication: state => state.subMerchantApplication,
  getSubMerchantApplicationNotes: state =>
    state.subMerchantApplication.logs
      .filter(log => log.type === 'note')
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime)),
  mySubMerchants: state => state.mySubMerchants,
  accountNames: state => state.accountNames,
  subMerchant: state => state.subMerchant,
  subMerchantLoans: state => state.subMerchantLoans,
  getAccountChecks: state => state.accountChecks,
  getSubmerchantSyncs: state => state.subMerchantSyncs,
  getSubMerchantChecks: state => state.subMerchantChecks,
  getApplicantChecks: state => state.applicantChecks,
  loanEligible: state => state.loanEligible,
  underwritingStatus: state => state.underwritingStatus,
  fullAccountData: state => state.fullAccountData
};

// mutations
const mutations = {
  [SET_ACKNOWLEDGE](state, data) {
    const subMerchantDocs = state.subMerchantApplication.subMerchantDocs.map(sm => {
      if (sm._id === data.subMerchantObjectId) {
        sm.rules.acknowledged = data.acknowledged;
        sm.acknowledgedAt = data.acknowledgedAt;
      }
      return sm;
    });
    state.subMerchantApplication.subMerchantDocs = subMerchantDocs;
  },
  [GET_SUBMERCHANTS](state, data) {
    state.list = data;
  },
  [GET_ACCOUNTS_TABLE](state, data) {
    state.accountList = data;
  },
  [SET_ACCOUNTS_TABLE_DATA](state, data) {
    state.accountList.data = data;
    state.accountList.totalCount = data.length;
  },
  [SET_SUBMERCHANT_APPLICATION](state, data) {
    state.subMerchantApplication = data;
  },
  [CHANGE_ACCOUNT_STATUS](state, status) {
    state.subMerchantApplication.status = status;
  },
  [ADD_NOTE](state, data) {
    state.subMerchantApplication.logs = data.logs;
  },

  [SET_MY_SUBMERCHANTS](state, data) {
    state.mySubMerchants = data;
  },
  [SET_ACCOUNT_NAMES](state, data) {
    state.accountNames = data;
  },

  [SET_SUBMERCHANT](state, data) {
    state.subMerchant = data;
  },
  [SET_FULL_ACCOUNT_DATA](state, data) {
    state.fullAccountData = data;
  },
  [SET_PREFERRED_ACCOUNT](state, index) {
    if (!state.subMerchant.payoutBankAccounts[index].isPreferred) {
      for (const account of state.subMerchant.payoutBankAccounts) {
        account.isPreferred = false;
      }
      state.subMerchant.payoutBankAccounts[index].isPreferred = true;
    }
  },
  [ADD_ACCOUNT](state, data) {
    if (data.isPreferred === true) {
      for (const account of state.subMerchant.payoutBankAccounts) {
        account.isPreferred = false;
      }
    }
    state.subMerchant.payoutBankAccounts.push(data);
  },
  [SET_SUBMERCHANT_LOANS](state, data) {
    state.subMerchantLoans = data;
  },
  [SET_ACCOUNTS_CHECKS](state, data) {
    state.accountChecks = data;
  },
  [SET_LOAN_ELIGIBLE](state, data) {
    state.loanEligible = data;
  },
  [SET_UNDERWRITING_STATUS](state, data) {
    state.underwritingStatus = data;
  },
  [SET_SUBMERCHANT_CHECKS](state, data) {
    state.subMerchantChecks = data;
  },
  [SET_SUBMERCHANT_SYNCS](state, data) {
    state.subMerchantSyncs = data;
  },
  [UPDATE_SUBMERCHANT_CHECK](state, data) {
    if (state.subMerchantChecks.length > 0) {
      const checkIndex = state.subMerchantChecks.findIndex(
        check => check._id === data._id
      );
      state.subMerchantChecks.splice(checkIndex, 1, data);
    } else {
      subMerchantChecks = [data];
    }
    const submerchantIndex = state.subMerchantApplication.subMerchantDocs.findIndex(
      sm => sm.subMerchantId === data.id
    );
    state.subMerchantApplication.subMerchantDocs[
      submerchantIndex
    ].rules.knowYourBusiness.passed = data.passed;
  },
  [UPDATE_APPLICATION_RULES](state, data) {
    state.subMerchantApplication.rules = data;
  },
  [UPDATE_SUBMERCHANT_RULES](state, data) {
    const { rules, subMerchantObjId } = data;
    const subMerchantsDocs = state.subMerchantApplication.subMerchantDocs.map(
      subMerchant => {
        if (subMerchant._id === subMerchantObjId) {
          subMerchant.rules = rules;
        }
        return subMerchant;
      }
    );
    state.subMerchantApplication.subMerchantDocs = subMerchantsDocs;
  },
  [UPDATE_SUBMERCHANT_RULE](state, data) {
    const { ruleName, ruleValue, subMerchantObjId } = data;
    const subMerchantsDocs = state.subMerchantApplication.subMerchantDocs.map(
      subMerchant => {
        if (subMerchant._id === subMerchantObjId) {
          subMerchant.rules[ruleName].passed = ruleValue;
        }
        return subMerchant;
      }
    );
    state.subMerchantApplication.subMerchantDocs = subMerchantsDocs;
  },
  [UPDATE_APPLICANT_RULE](state, data) {
    const { ruleName, ruleValue, subMerchantObjId } = data;
    state.subMerchantApplication.rules[ruleName].passed = ruleValue;
  },
  [SET_SUBMERCHANT_DOCS](state, subMerchantDocs) {
    state.subMerchantApplication.subMerchantDocs = subMerchantDocs;
  },
  [SET_SUBMERCHANT_RISK_METRICS](state, { subMerchantId, riskMetrics }) {
    const updateRiskMetrics = subMerchantDocs => {
      const subMerchantDocIndex = subMerchantDocs?.findIndex(
        sm => sm?.subMerchantId === subMerchantId
      );

      if (subMerchantDocIndex !== -1 && subMerchantDocIndex != null) {
        const subMerchantDoc = subMerchantDocs[subMerchantDocIndex];
        if (subMerchantDoc?.risk?.rules) {
          const ruleIndex = subMerchantDoc.risk.rules.findIndex(
            rule => rule?.ruleId === riskMetrics?.ruleId
          );

          if (ruleIndex !== -1 && ruleIndex != null) {
            const rule = subMerchantDoc.risk.rules[ruleIndex];
            if (rule) {
              rule.params = { ...rule.params, ...riskMetrics?.params };
              rule.enabled = riskMetrics?.enabled;

              subMerchantDocs[subMerchantDocIndex].risk.rules[ruleIndex] = rule;
            }
          }
        }
      }
    };

    updateRiskMetrics(state.subMerchantApplication?.subMerchantDocs);
    updateRiskMetrics(state.list?.data);

    state.list = { ...state.list };
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
