import DashboardPlugin from '@/plugins/dashboard-plugin';
import merchantModule from '@/store/Merchant';
import serviceProviderModule from '@/store/ServiceProvider';
import { COMMONS_URLS, ENVIRONMENTS, JUPITER_APPS } from '@commons/data/constants';
import store from '@commons/store';
import axios from 'axios';
import IdleVue from 'idle-vue';
import Maska from 'maska';
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import config from './config';
import router from './routes/router';

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: 3600000,
  startAtIdle: false,
  events: ['keydown', 'mousedown', 'touchstart']
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Loading, {
  backgroundColor: 'transparent',
  opacity: 0.2,
  isFullPage: false
});
Vue.use(Maska);
// Plugins from Onboarding Project
Vue.use(VueTheMask);
// end

// Axios setup
axios.defaults.baseURL = config.ROOT_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

store.registerModule('serviceProvider', serviceProviderModule);
store.registerModule('merchant', merchantModule);

let vueApp = new Vue({ store });
const loader = vueApp.$loading.show();
vueApp.$store.commit('user/SET_JUPICO_APP', JUPITER_APPS.PAYMENTS);
vueApp.$store
  .dispatch('GET_FACILITATOR')
  .then(async () => {
    try {
      initializeVue(await vueApp.$store.dispatch('SSO'));
    } catch (error) {
      vueApp.$store.dispatch('CLEAR_AUTH_DATA');
      if (config.ENV !== ENVIRONMENTS.LOCAL || !config.BYPASS_COMMONS_LOGIN)
        window.location.replace(`${COMMONS_URLS.LOGIN_PAYMENTS}?ssoe`);
      else initializeVue();
    }
  })
  .catch(() => {})
  .finally(() => loader.hide());

function initializeVue(user) {
  const token = vueApp.$store.getters['getAuthToken'];
  Vue.prototype.$config = config;
  /* eslint-disable no-new */
  vueApp = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
  });
  vueApp.$store.commit('user/SET_JUPICO_APP', JUPITER_APPS.PAYMENTS);
  if (
    user &&
    (location.pathname === '/' || (token && location.pathname === '/dev/login'))
  ) {
    if (
      user.acl.find(a => a.role === 'merchant-manager') ||
      user.acl.find(a => a.role === 'merchant-accounting') ||
      user.acl.find(a => a.role === 'merchant-employee') ||
      user.acl.find(a => a.role === 'admin') ||
      user.acl.find(a => a.role === 'underwriter')
    )
      vueApp.$router.push({ name: 'Dashboard' });
    else if (user.acl.find(a => a.role === 'service-provider'))
      vueApp.$router.push({ name: 'AccountsCenter' });
    else if (user.acl.length === 0 && user.acl.find(a => a.role === 'merchant-admin'))
      vueApp.$router.push({ name: 'Manage' });
    else vueApp.$router.push({ name: 'AccountsView' });
  }
}
